import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Environment from '../views/Environment';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Alert from "react-bootstrap/Alert";
import OrderButton from './orderButton';
import Spinner from 'react-bootstrap/esm/Spinner';
import Button from 'react-bootstrap/esm/Button';

function DisplayOrders(props){

    const [productOrders, setProductOrders] = useState([]);
    const [error, setError] = useState('');
    const [orderButtons, setOrderButtons] = useState();
    const [spinner, setSpinner] = useState(false);
    const [checkBoxes, setCheckBoxes] = useState({});
    const [activeChecks, setActiveChecks] = useState(0);

    function modifyOrder(attribute, status){
        fetch(`${Environment()}/orders/modifyOrdersStatus`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'checkBoxes': checkBoxes,
                'attribute': attribute,
                'status': status
            })
        }).then(() => {
            window.location.reload();
        })
        .catch(err => {
            console.error(err);
        });
    }
    
    useEffect(() => {
        if (props.date){
            fetch(`${Environment()}/orders/getOrdersByWeek/${props.date}/${props.datePlusSeven}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(data => data.json())
            .then((data) => {
                setProductOrders(data);
            })
            .catch(err => {
                console.error(err);
                setError('Ocurrió un error. Inténtalo más tarde.')
            });
        }
    }, [props.date, props.datePlusSeven]);

    useEffect(() => {
        setSpinner(true);
        setOrderButtons(Object.keys(productOrders).reverse().map((order, index) => (
            <Col key={productOrders[order][0].idOrder}>
                <OrderButton handleCheck={e => { setCheckBoxes({...checkBoxes, [e.target.id]:!checkBoxes[e.target.id]}); checkBoxes[e.target.id] ? setActiveChecks(activeChecks + 1) : setActiveChecks(activeChecks - 1)}} isOutForDelivery={productOrders[order][0].isOutForDelivery} isSent={productOrders[order][0].isSent} isPayed={productOrders[order][0].isPayed} isReceived={productOrders[order][0].isReceived} isCanceled={productOrders[order][0].isCanceled} idOrder={productOrders[order][0].idOrder} estimatedDelivery={productOrders[order][0].estimatedDelivery} userName={productOrders[order][0].firstName + " " + productOrders[order][0].lastName}/>                    
            </Col>
        )))
        setSpinner(false);
    }, [productOrders, checkBoxes, activeChecks])

    return(
        <div>
            <div className='mt-1 text-center'>
                <Spinner
                hidden={!spinner}
                as="span"
                className='justify-content-center'
                animation="border"
                variant='success'
                size="lg"
                role="status"
                />
            </div>
            <Container className='text-center'>
                <Alert hidden={error.length === 0} variant='danger'>{error}</Alert>
            </Container>
            <Container hidden={activeChecks === 0} className='text-end'>
                <p>Marcar como:</p>
                <Button className='PrimaryBtn me-2' onClick={() => {modifyOrder('isOutForDelivery', true)}}>Enviado <ion-icon item-start size='small' name="boat-outline" class="icon"/></Button>
                <Button className='PrimaryBtn me-2' onClick={() => {modifyOrder('isReceived', true)}}>Entregado <ion-icon item-start size='small' name="nutrition-outline" class="icon"/></Button>
                <Button className='PrimaryBtn mb-2' onClick={() => {modifyOrder('isPayed', true)}}>Pagado <ion-icon item-start size='small' name="card-outline" class="icon"/></Button>
            </Container>
            <Container hidden={activeChecks === 0} className='text-end'>
                
                <Button variant='warning' className='me-2' onClick={() => {modifyOrder('isOutForDelivery', false)}}>Sin enviar <ion-icon item-start size='small' name="boat-outline" class="icon"/></Button>
                <Button variant='warning' className='me-2' onClick={() => {modifyOrder('isReceived', false)}}>No entregado <ion-icon item-start size='small' name="nutrition-outline" class="icon"/></Button>
                <Button variant='warning' onClick={() => {modifyOrder('isPayed', false)}}>Sin pagar <ion-icon item-start size='small' name="card-outline" class="icon"/></Button>
            </Container>
            <Row xs={2} md={3} className="mt-3 g-4">
                {orderButtons}
            </Row>
        </div>
    );
}
export default DisplayOrders;