import React, { useState } from 'react';
import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function DeleteFolderModal(props) {
  const [error, setError] = useState('');

  function redirect(link){
    window.location.href = link;
  }

  async function DeleteFolder(e){
    fetch(`${Environment()}/images/deleteFolder`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({idFolder:props.idFolder}),
    })
    .then(() => {
      redirect('/gestionarimagenes');
    })
    .catch(err => {
      console.error(err);
      setError("En este momento no se puede eliminar la carpeta. Inténtalo más tarde.");
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Eliminar carpeta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ¿Estás seguro de que quieres eliminar esta carpeta y todas sus imágenes?
        </p>
        <p className='text-danger'>
          {props.name}
          {props.idFolder}
        </p>
        {error}
      </Modal.Body>
      <Modal.Footer>
        <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
        <Button className='btn-danger' onClick={(e) => DeleteFolder(e)}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteFolderModal;