import { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/esm/Container';
import AdminBar from '../components/AdminBar';
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Col from 'react-bootstrap/esm/Col';
import Row from "react-bootstrap/esm/Row";
import Environment from '../views/Environment';
import './views.css';

function AdminPictures() {
  const [folders, setFolders] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [modifyFolderName, setModifyFolderName] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(0);
  const formRefs = useRef({});

  function NewFolder(e){
    e.preventDefault()
    fetch(`${Environment()}/images/postFolder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: newFolderName
      })
    })
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
  }

  useEffect(() => {
    fetch(`${Environment()}/images/getFolders`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setFolders(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [selectedFolder])

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${Environment()}/images/modifyFolder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: modifyFolderName,
        idFolder: selectedFolder
      })
    })
    .then(() => {
      setSelectedFolder(0);
      setModifyFolderName('');
    })
    .catch(err => {
        console.error(err);
    });
 };

  const handleDoubleClick = (folderId, folderName) => {
    setSelectedFolder(folderId);
    setModifyFolderName(folderName);

    setTimeout(() => {
        if (formRefs.current[folderId]) {
            formRefs.current[folderId].focus();
            formRefs.current[folderId].select();
        }
    }, 0);
  };

  const foldersRows = Array.from(folders).map((folder, idx) => {
    return(
    <Col key={idx} className='text-center'>
      <a href={"/gestionarimagenes/" + folder.idFolder}><ion-icon class='green icon large-icon' name='folder-sharp'/></a>
      <p hidden={folder.idFolder === selectedFolder} onDoubleClick={() => handleDoubleClick(folder.idFolder, folder.name)}>{folder.name}</p>
      <div className='d-flex justify-content-center'>
        <Form.Control 
          hidden={folder.idFolder !== selectedFolder}
          value={modifyFolderName}
          onChange={(e) => setModifyFolderName(e.target.value)}
          size='sm'
          className="w-25"
          id={folder.idFolder}
          onBlur={handleSubmit}
          ref={(el) => (formRefs.current[folder.idFolder] = el)}
        />
      </div>
    </Col>
    )
  });

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <Container className='mb-5'>
          <h3 className='mb-5'><b><span className='green'>Galería</span></b></h3>
          <Form onSubmit={(e) => NewFolder(e)}>
            <InputGroup>
              <Form.Control 
                required
                type='text'
                maxLength={32}
                onChange={(e) => setNewFolderName(e.target.value)}
                value={newFolderName}
                placeholder='Nombre de la carpeta'
              />
              <Button type='submit' className='PrimaryBtn'>Nueva carpeta <ion-icon name="add"/></Button>
            </InputGroup>
          </Form>
        </Container>
        <Container>
          <Row md={3} lg={3} xl={3} className="mt-3 g-4">
            {foldersRows}
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default AdminPictures;