import React, { useEffect, useState } from 'react';
import './components.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Environment from './../views/Environment';
import ModalSeason from './modalSeason';
import Spinner from 'react-bootstrap/esm/Spinner';

function CardProduct(props) {
    
    const [product, setProduct] = useState({
        quantity:1,
        idProduct:'',
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [UM, setUM] = useState(props.singular);
    const [modalShowSeason, setModalShowSeason] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const handleClose = () => setModalShowSeason(false);
    const handleOpen = () => setModalShowSeason(true);

    useEffect(() => {
        if(product.quantity === '1' || product.quantity === 1){
            setUM(props.singular);
        } else{
            setUM(props.plural);
        }
    }, [product.quantity, props.singular, props.plural])
    

    function updateQuantity(e){
        updateForm({quantity: e.currentTarget.value});
    }

    function addToCart(e){
        e.preventDefault();
        setSpinner(true);
        fetch(`${Environment()}/login/isLoggedIn`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(sessionData => sessionData.json())
        .then((sessionData) => {
            if(sessionData.isLoggedIn){
                fetch(`${Environment()}/orders/getCart/${sessionData.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(orderData => orderData.json())
                .then((orderData) => {
                    fetch(`${Environment()}/orders/getCartProducts/${orderData.idOrder}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(productsData => productsData.json())
                    .then((productsData) => {
                        const result = productsData.reduce((acc, item) => {
                            acc[item.idProduct] = item;
                            return acc;
                        }, {});
                        if(Number(props.availability) < Number(product.quantity) + (result[props.id] ? Number(result[props.id].quantity) : 0)){
                            setError('Solamente tenemos ' + Number(props.availability) + ' ' + (Number(props.availability) > 1 ? props.plural : props.singular) + ' de ' + props.name + '. Escribe una cantidad más pequeña.');
                            setSpinner(false);
                            return;
                        }
                        fetch(`${Environment()}/orders/postCartProduct`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                ...product, 
                                idOrder:orderData.idOrder,
                                soldPrice: props.price,
                                soldDiscount:props.discount
                            })
                        })
                        .then(() => {
                            setError('');
                            setSuccess('Producto añadido exitosamente.');
                            setSpinner(false);
                            setTimeout(function () {
                                setSuccess('');
                            }, 3000);
                        })
                        .catch(err => {
                            setSpinner(false);
                            console.error(err);
                            setError("Lo sentimos. Ocurrió un error.");
                            setTimeout(function () {
                                setError('');
                            }, 3000);
                        });
                    })
                    .catch(err => {
                        setSpinner(false);
                        console.error(err);
                        setError("Lo sentimos. Ocurrió un error.");
                        setTimeout(function () {
                            setError('');
                        }, 3000);
                    });
                })
                .catch(err => {
                    setSpinner(false);
                    console.error(err);
                    setError("Lo sentimos. Ocurrió un error.");
                    setTimeout(function () {
                        setError('');
                    }, 3000);
                });
            }else{
                setSpinner(false);
                setError('Inicia sesión para añadir productos al carrito.');
            }
        })
        
    }

    /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
        return setProduct((prev) => ({ ...prev, ...value }));
    }
    
    return (
        <div className="card mb-3 shadow bg-white">
            {success &&
                <div className='text-center mb-3 mt-2'>
                    <span className='text-success'><ion-icon size='large' name="checkmark-circle-sharp"></ion-icon></span>
                    <p className='text-success'>{success}</p>
                </div>
            }
            <a href={`http://localhost:3000/productoDetallado/${props.id}`} className={`text-black text-decoration-none transparent ${!props.isSeason || !props.isInStock? "blur-container" : ""}`}>
                <img className="card-img-top" src={`${Environment()}/image${props.idImage}${props.imgExtension}`} alt={props.name}/>
            </a>
            < div className='mt-1 text-center'>
                <Spinner
                hidden={!spinner}
                as="span"
                className='justify-content-center'
                animation="border"
                variant='success'
                size="lg"
                role="status"
                />
            </div>
            <Form onSubmit={(e) => {addToCart(e)}} className="card-body">
                <p className="card-text text-muted"><small>{props.category}</small></p>
                <a href={`http://localhost:3000/productoDetallado/${props.id}`} className="text-black text-decoration-none transparent">
                    <h6 className="card-title"><strong>{props.name} </strong></h6>
                </a>
                <div className='row'>
                            {parseFloat(props.discount) > 0 &&
                                <div className='col'>
                                    <h6 className='text-muted'><del>{`${"$"}${(parseFloat(props.discount) + parseFloat(props.price)).toFixed(2)}`}</del></h6>
                                </div>
                            }
                    <div className='col'>
                        <h6 className="card-title"><strong>{`${"$"}${props.price}/${props.finalUnit}`}</strong></h6>
                    </div>
                </div>
                <div className='row text-center align-items-center mt-3' hidden={props.hiddeBuyButton === "true"}>
                    <div className='col'>
                        <Form.Group>
                            <Form.Control
                                required
                                disabled={!props.isInStock}
                                value={product.quantity}
                                type="number"
                                step={props.isFloat === 1 ? '0.1' : '1'}
                                min={props.isFloat === 1 ? '0.1' : '1'}
                                onChange={(e) => updateQuantity(e)}
                            />
                        </Form.Group>
                    </div>
                    <div className='col'>
                        <p className="card-text"><small className="text-muted">{UM}</small></p>
                    </div>
                </div>
                <div className='row text-center mt-3' hidden={props.hiddeBuyButton === "true"}>
                    {props.isInStock ? (props.isSeason && props.isInStock ?
                        <Button type="submit" className='BuyButton' onClick={() => updateForm({idProduct:props.id})}><ion-icon name="cart-outline"></ion-icon><small> Añadir al carrito</small></Button>
                        :
                        <Button className='btn-secondary' onClick={() => handleOpen()}><small> Fuera de temporada</small></Button>)
                        :
                        <Button disabled className='btn-danger'><small> Agotado</small></Button>
                    } 
                </div>
                <p className='text-danger'>{error}</p>
            </Form>

            <ModalSeason
                onHide={() => handleClose()}
                show={modalShowSeason}
            />
        </div>
    )
}

export default CardProduct;