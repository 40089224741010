import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Environment from '../views/Environment';
import './components.css';

function NewImageModal(props) {

  const [newImage, setNewImage] = useState('');
  const [name, setName] = useState('');
  const [scientificName, setScientificName] = useState('');
  const [video, setVideo] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [formError, setFormError] = useState('');

  function onSubmit(e) {
    e.preventDefault();
    if(newImage && newImage.type !== "image/png" && newImage.type !== "image/jpg" && newImage.type !== "image/jpeg"){
      setFormError("La imagen adjuntada no es png, jpg o jpeg.");
      return;
    }

    const formData = new FormData();
    formData.append('image', isVideo ? null : newImage);
    formData.append('name', name);
    formData.append('video', isVideo ? video : null);
    formData.append('isVideo', isVideo);
    formData.append('scientificName', scientificName);
    formData.append('idFolder', props.idFolder);
    
    fetch(`${Environment()}/images/postImage`, {
      method: 'POST',
      body: formData
    })
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
      setFormError("Lo sentimos. Ocurrió un error. Inténtalo más tarde.");
      console.error(err);
    });
        
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={e => onSubmit(e)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>{props.title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label><b>Nombre común *</b></Form.Label>
          <Form.Control 
            required
            type='text'
            className='mb-3'
            maxLength={32}
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Label><b>Nombre científico (opcional)</b></Form.Label>
          <Form.Control 
            type='text'
            className='mb-3'
            maxLength={32}
            onChange={(e) => setScientificName(e.target.value)}
          />
          <p><b>Imagen o video*</b></p>
          <Button  className="mb-3" variant='secondary' onClick={() => setIsVideo(!isVideo)}>
            { isVideo &&
              <span>Adjuntar imagen</span>
            }
            { !isVideo &&
              <span>Adjuntar video</span>
            }
          </Button>
          <Form.Group hidden={!isVideo} className="mb-3">
            <Form.Label>Video</Form.Label>
            <Form.Control
              required={isVideo}
              type="text"
              placeholder="https://youtu.be/NJ1CBZ34WyQ"
              onChange={(e) => setVideo(e.target.value)}
              value={video}
            />
          </Form.Group>
          <Form.Control
            hidden={isVideo} 
            required={!isVideo}
            type='file'
            className='mb-3'
            onChange={(e) => setNewImage(e.target.files[0])}
          />
          <Alert hidden={!formError} variant='danger'>{formError}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewImageModal;