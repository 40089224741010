import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import ProgressBar from 'react-bootstrap/ProgressBar';
import AdminBar from "../components/AdminBar";
import CancelOrderModal from '../components/cancelOrderModal';
import WasteModal from '../components/wasteModal';
import Environment from './Environment';
import './views.css'

function SupplierOrder(){
    let { idOrder } = useParams();
    const [order, setOrder] = useState({});
    const [products, setProducts] = useState([]);
    const [productsSearch, setProductsSearch] = useState([]);
    const [orderProducts, setOrderProducts] = useState({});

    const [orderProgress, setOrderProgress] = useState(1);

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showWasteModal, setShowWasteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [selectedQuantity, setSelectedQuantity] = useState(0);
    const [waste, setWaste] = useState(0);

    const [modalShowImage, setModalShowImage] = useState(false);
    const [selectedPicture, setSelectedPicture] = useState(0);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const numFor = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    const total = Array.from(orderProducts).reduce((partialSum, product) => partialSum + ((order.isSent ? product.soldCost : product.price)*product.quantity*product.unitRelation), 0);

    function onWasteClick(e){
        setSelectedProduct(e.currentTarget.id);
        setWaste(e.currentTarget.name);
        setSelectedQuantity(e.currentTarget.value);
        setShowWasteModal(true);
    }

    const handleCaptureClick = () => {
        fetch(`${Environment()}/supplierOrder/sendOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:idOrder,
                    isSent: !order.isSent
                })
            })
            .then(() => {
                if(order.isSent){
                    receiveOrder();
                    modifyPayment();
                } else
                window.location.reload();
            })
            .catch(err => {
                console.error(err);
            });
    };

    useEffect(() => {
        
    }, [order.isSent])

    function modifyPayment(isOrderSent){
        fetch(`${Environment()}/payments/modifyPayment`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:idOrder,
                    isIncome: false,
                    description: 'Pedido a ' + order.supplierName + ' con ID:' + idOrder.toString(),
                    amount: total.toFixed(2),
                    isPayed: !isOrderSent? false : !order.isPayed,
                    form: "N/A"
                }
            )
        })
        .then(() => {
            window.location.reload();
        })
        .catch(err => {
            console.error(err);
        });
    }

    function receiveOrder(isOrderSent){
        fetch(`${Environment()}/supplierOrder/receiveOrder`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:idOrder,
                    isReceived: !isOrderSent? false : !order.isReceived
                }
            )
        })
        .then(() => {
            window.location.reload();
        })
        .catch(err => {
            console.error(err);
        });
    }

    function addProduct(e){
        e.preventDefault();
        const idProduct = e.currentTarget.id;
        const quantity = document.getElementById('quantity'+ idProduct).value;
        fetch(`${Environment()}/supplierOrder/postProductOrder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:idOrder,
                    idProduct: idProduct,
                    quantity: quantity
                }
            )
        })
        .then(() => {
            window.location.reload();
        })
        .catch(err => {
            console.error(err);
        });
    }

    function deleteProduct(e){
        e.preventDefault();
        const idProduct = e.currentTarget.id;
        fetch(`${Environment()}/supplierOrder/deleteProductOrder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    idOrder:idOrder,
                    idProduct: idProduct
                }
            )
        })
        .then(() => {
            window.location.reload();
        })
        .catch(err => {
            console.error(err);
        });
    }

    const handleCloseImage = () => setModalShowImage(false);
    function handleShowImage(e){
        setModalShowImage(true);
        setSelectedPicture(e.currentTarget.id);
    }

    function filterProducts(e){
        e.preventDefault();
        const product = e.currentTarget.value;
        if(!product){
            setProductsSearch('');
        }
        else{
            setProductsSearch(products?.filter((row) => row?.name?.match(new RegExp(product, "i"))));
        }
    }

    useEffect(() => {
        fetch(`${Environment()}/supplierOrder/getOrderById/${idOrder}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setOrder(data);
            let progress = 1;
            if(data.isSent){
                progress += 33;
            }
            if(data.isReceived){
                progress += 67;
            }
            if(progress > 100){
                progress = 100;
            }
            setOrderProgress(progress)
        })
        .catch(err => {
            console.error(err);
        });
    }, [idOrder])

    useEffect(() => {
        fetch(`${Environment()}/products/getSupplierProducts/${order.idSupplier}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setProducts(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [order])

    useEffect(() => {
        fetch(`${Environment()}/supplierOrder/getOrderProductsById/${idOrder}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setOrderProducts(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [idOrder])

    
    const allProducts = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
        <Form onSubmit={addProduct} id={product.idProduct} key={product.idProduct} className="mb-3">
            <InputGroup>
                <InputGroup.Text>{product.name}</InputGroup.Text>
                <InputGroup.Text>{numFor.format(product.price*product.unitRelation)}</InputGroup.Text>
                <Form.Control 
                required
                disabled={order.isSent || order.isDeleted}
                type='number'
                step={product.isFloat ? '0.1' : '1'}
                min={product.isFloat ? '0.1' : '1'}
                xs={2}
                id={'quantity' + product.idProduct}
                />
                <InputGroup.Text>{product.pluralName}</InputGroup.Text>
                <Button disabled={order.isSent || order.isDeleted} className='PrimaryBtn' type='submit'><ion-icon name="add-outline"></ion-icon> Añadir</Button>
            </InputGroup>
        </Form>
    ));

    const productRows = Array.from(orderProducts).map((product, idx) => (
        <tr key={product.idProduct}>
            <td><Image id={`${product.idImage}${product.imgExtension}`} onClick={(e) => {handleShowImage(e)}} style={{ width: "calc(50px + 3vw)" }} src={`${Environment()}/image${product.idImage}${product.imgExtension}`}></Image></td>
            <td>{product.name}</td>
            <td>{product.categoryName}</td>
            <td>{product.shortDescription}</td>
            <td>{product.quantity} {product.pluralName}</td>
            <td>{product.waste ? product.waste : 0} {product.pluralName}</td>
            <td>{product.notes}</td>
            <td>{numFor.format((order.isSent ? product.soldCost : product.price)*product.unitRelation)}/{product.singularName}</td>
            <td>{numFor.format((order.isSent ? product.soldCost : product.price)*(product.quantity - product.waste)*product.unitRelation)}</td>
            <td className='text-center'><Button onClick={(e) => {deleteProduct(e)}} disabled={order.isSent} id={product.idProduct} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
            <td className='text-center'><Button onClick={(e) => {onWasteClick(e)}} className='me-3' variant='outline-secondary' value={product.quantity} id={product.idProduct} name={product.waste}><ion-icon name="color-fill"/></Button></td>
        </tr>
    ));

    const productRowsImage = Array.from(orderProducts).map((product, idx) => (
        <tr key={product.idProduct}>
            <td>{product.name}</td>
            <td>{product.quantity} {product.pluralName}</td>
            <td>${order.isSent ? product.soldCost : product.price}</td>
        </tr>
    ));

    const receivedButton = (
        <Col>
            <Button onClick={() => {receiveOrder(true)}} variant='outline-success'>
                <ion-icon item-start size='large' name="mail-outline" class="icon"></ion-icon>
            </Button>
            <Form.Check
                disabled 
                label='Recibido'
                title='Marcar como recibido'
                defaultChecked={order.isReceived}
            />
        </Col>
    );

    return(
        <Container className='Layout'>
            <Row>
                <Breadcrumb>
                <Breadcrumb.Item href="/administracion">Inicio</Breadcrumb.Item>
                <Breadcrumb.Item href="/pedidosproveedores">Entradas de inventario</Breadcrumb.Item>
                <Breadcrumb.Item active>Pedido a {order.supplierName}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row>
                <AdminBar active='/pedidos'></AdminBar>
            </Row>
            <Row className='mb-4'>
                <h3 className='mb-3'><b>Pedido a <span className='green'>{order.supplierName}</span></b></h3>
                <h3 className='mb-3 text-danger' hidden={!order.isDeleted}><b>Cancelado</b></h3>
                <div className='text-muted'>
                    <p>{new Date(order.dateTime).toLocaleDateString('es-MX', options)}</p>
                    <p>{new Date(order.dateTime).toLocaleTimeString('en-US')}</p>
                    <p>ID: {idOrder}</p>
                </div>
            </Row>
            <Row hidden={order.isDeleted}>
                <Col>
                    <Button  crossOrigin="anonymous" onClick={() => {handleCaptureClick()}} variant='outline-success'>
                        <ion-icon item-start size='large' name="send" class="icon"></ion-icon>
                    </Button>
                    <Form.Check
                        disabled
                        label='Enviado'
                        defaultChecked={order.isSent}
                    />
                </Col>
                
                {receivedButton}
      
            </Row>
            <Row hidden={order.isDeleted} className='text-start mt-3'>
                <Col>
                    <ProgressBar variant="success" now={orderProgress} />
                </Col>
            </Row>
            <Accordion hidden={order.isDeleted} className='mt-5'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <b><ion-icon name="add"/><ion-icon name="nutrition"/> Añadir productos al pedido</b>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='mt-2'>
                            <Col xl={6}>
                                <Form.Control
                                onChange={(e) => {filterProducts(e)}}
                                className='mb-3'
                                placeholder="Buscar productos"
                                />
                                <Container className='productScroll'>
                                {allProducts}
                                </Container>
                            </Col>
                        </Row>
                    </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <Table responsive className='mt-5'>
                <thead>
                    <tr>
                        <th>Imagen</th>
                        <th>Nombre</th>
                        <th>Categoría</th>
                        <th>Descripción corta</th>
                        <th>Cantidad</th>
                        <th>Merma</th>
                        <th>Justificación de merma</th>
                        <th>Valor unitario</th>
                        <th>Valor total</th>
                        <th className='text-center'>Eliminar</th>
                        <th className='text-center'>Merma</th>
                    </tr>
                </thead>
                <tbody>
                    {productRows}
                    <tr>
                        <td colSpan={12} className='text-end'>Total: <b>${total.toFixed(2)}</b></td>
                    </tr>
                </tbody>
            </Table>
            <Button 
            variant='danger' 
            className='mt-5 mb-5'
            hidden={order.isDeleted}
            onClick={(e) => {setShowCancelModal(true)}}>
                Cancelar orden
            </Button>
            <Button 
            variant='danger' 
            className='mt-5 mb-5'
            hidden={!order.isDeleted}
            onClick={(e) => {setShowCancelModal(true)}}>
                Habilitar orden
            </Button>
            <Modal className='d-flex justify-content-center' centered size="lg" show={modalShowImage} onHide={handleCloseImage}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <Image 
                style={{ width: "calc(50px + 40vw)" }} 
                src={`${Environment()}/image${selectedPicture}`}>
                </Image>
                </Modal.Body>
            </Modal>
            <CancelOrderModal
            id={idOrder}
            name={'Pedido creado para ' + order.supplierName + ' el ' + new Date(order.dateTime).toLocaleDateString('es-MX', options) + ' a las ' + new Date(order.dateTime).toLocaleTimeString('es-MX')}
            show={showCancelModal}
            isDeleted={order.isDeleted}
            onHide={() => setShowCancelModal(false)}
            />
            <WasteModal
            show={showWasteModal}
            title='Registrar merma'
            description='Escribe la cantidad de merma que hubo en esta orden.'
            idproduct={selectedProduct}
            idorder={idOrder}
            quantity={selectedQuantity}
            waste={waste}
            onHide={() => setShowWasteModal(false)}
            />
            <Container hidden id={'table'} className='pb-3 pt-3'>
                <h4><b>Pedido a {order.supplierName}</b></h4>
                <div className='text'muted>
                    <p>ID: {idOrder}</p>
                    <p>Fecha de creación: {new Date(order.dateTime).toLocaleDateString('es-MX', options)}</p>
                    <p>Enviado el: {new Date().toLocaleDateString('es-MX', options)}</p>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productRowsImage}
                        <tr>
                            <td colSpan={6} className='text-end'>Total: <b>${total.toFixed(2)}</b></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </Container>
    );
}
export default SupplierOrder;