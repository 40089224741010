import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Environment from '../views/Environment';
import './components.css';

function NewCategoryModal(props) {

  const [productImages, setProductImages] = useState([]);
  const [images, setImages] = useState({});
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(1);
  const cardsPerPage = 4;
  const [formError, setFormError] = useState('');
  const [newCategory, setNewCategory] = useState(
    {
      name: '',
      shortDescription: '',
      image: ''
    }
  );

  const addImage = (newItem) => {
    const itemExists = productImages.some(item => item.idImage === newItem.idImage);

    if (!itemExists) {
      setProductImages(prevItems => [...prevItems, newItem]);
    }
  };

  const removeImage = (idImage) => {
    setProductImages(prevItems => prevItems.filter(item => item.idImage !== idImage));
  };

  useEffect(() => {
    fetch(`${Environment()}/images/getPages/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data[0].results > 0)
        setPages(Math.ceil(data[0].results / cardsPerPage)); 
    })
    .catch(err => {
        console.error(err);
    });
  }, [])

  useEffect(() => {
    fetch(`${Environment()}/images/getImages/${cardsPerPage}/${active}/false`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setImages(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [cardsPerPage, active])

   /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
    function updateForm(value) {
      return setNewCategory((prev) => ({ ...prev, ...value }));
  }

   /**
   * onSubmit
   * @description Posts package product through a fetch to the server
   * @param e: Context
   */
    async function onSubmit(e) {
      e.preventDefault();

      if(productImages.length < 1){
        setFormError("Adjunta una imagen.")
        return;
      }

      const formData = new FormData();
      formData.append('idImage', productImages[0].idImage);
      formData.append('name', newCategory.name);
      formData.append('shortDescription', newCategory.shortDescription);

      fetch(`${Environment()}/categories/getCategory/${newCategory.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length){
          setFormError("El nombre de categoría ya existe");
          return;
        }
        else{
          fetch(`${Environment()}/categories/postCategory`, {
            method: 'POST',
            body: formData
          });
          
          setNewCategory(
            {
              name: '',
              shortDescription: '',
              image: ''
            }
          );
            
          window.location.reload();
        }
      })
  }

  const addedImagesRows = Array.from(productImages).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                variant='grey' 
                onClick={() => removeImage(image.idImage)}
              >
                <ion-icon
                name="trash"
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>                
        </Card.Body>
      </Card>
    </Col>
  ));

  const imageRows = Array.from(images).filter(image => image.isVideo === 0).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Button 
                className='PrimaryBtn'
                data-cy={idx === 0 ? "image category" : ''}
                onClick={() => productImages < 1 ? addImage({ idImage: image.idImage, imgExtension: image.imgExtension }) : ''}
              >
                <ion-icon 
                name="add" 
                />
              </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Img className='mb-5' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>                
          <Card.Text className='text-center'><b><small>{image.name}</small></b></Card.Text>
          <Card.Text className='text-center'><i><small>{image.scientificName}</small></i></Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ));

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>
            {props.description}
          </p>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryName" className='green'><b>Nombre *</b></Form.Label>
              <Form.Control
                required
                data-cy="category name"
                type="text"
                id="inputCategoryName"
                aria-describedby="categoryName"
                placeholder="Verduras"
                onChange={(e) => updateForm({ name: e.target.value })}
                value={newCategory.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="inputCategoryDesc" className='green'><b>Descripción corta *</b></Form.Label>
              <Form.Control
                required
                data-cy="category description"
                type="text"
                id="inputCategoryDesc"
                aria-describedby="categoryDescription"
                placeholder="Hortalizas, bulbos, raíces y más"
                onChange={(e) => updateForm({ shortDescription: e.target.value })}
                value={newCategory.shortDescription}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='green'><b>Imagen *</b></Form.Label>
              <p>Selecciona una imagen</p>
              <Container className='mt-3'>
              <Row md={4} lg={4} xl={4}>
                {addedImagesRows}
              </Row>
              </Container>
              <Container className='mt-3'>
                <Row>
                  <Col>
                    <ion-icon onClick={ e => {setActive(active <= 1 ? 1 : active - 1)}} name="arrow-back-outline"></ion-icon>
                  </Col>
                  <Col className='text-center'>
                    <p><b>Galería</b></p>
                  </Col>
                  <Col className='text-end'>
                    <ion-icon onClick={ e => {setActive(active >= pages ? pages : active + 1)}} name="arrow-forward-outline"></ion-icon>
                  </Col>
                </Row>
                <Row md={4} lg={4} xl={4}>
                  {imageRows}
                </Row>
              </Container>
            </Form.Group>
            <p className='text-danger'>{formError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
          <Button data-cy="submit category" type='submit' className='PrimaryBtn'>Aceptar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewCategoryModal;