import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import NavbarUser from '../components/navbar';
import Environment from './Environment';
import FooterUser from '../components/footerUser';
import './views.css'

function MyOrders() {
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const [products, setProducts] = useState({});
  const [session, setSession] = useState({});
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [UMs, setUMs] = useState([]);

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});

    useEffect(() => {
      fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(data => data.json())
      .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
      })
      .catch(err => {
          console.error(err);
      });
  }, [])

  const orderYears = [];
  for (let i = -1; i < 3; i++) {
    orderYears.push(new Date().getFullYear()-i);
  }

  useEffect(() => {
    fetch(`${Environment()}/login/isLoggedIn`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(sessionData => sessionData.json())
    .then((sessionData) => {
      setSession(sessionData);
      if(sessionData.isLoggedIn){
        fetch(`${Environment()}/orders/orderProductsByUser/${sessionData.email}/${selectedYear}/${selectedMonth}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(orderData => orderData.json())
        .then((orderData) => {
          setProducts(orderData);
        })
        .catch(err => {
            console.error(err);
        });
      }
    })
  }, [selectedYear, selectedMonth])

  const selectYears = Array.from(orderYears).map((year, idx) => (
    <option key={year} value={year}>{year}</option>
  ));

  const months = [
                  {number:1, name:'enero'}, 
                  {number:2, name:'febrero'},
                  {number:3, name:'marzo'},
                  {number:4, name:'abril'},
                  {number:5, name:'mayo'},
                  {number:6, name:'junio'},
                  {number:7, name:'julio'},
                  {number:8, name:'agosto'},
                  {number:9, name:'septiembre'},
                  {number:10, name:'octubre'},
                  {number:11, name:'noviembre'},
                  {number:12, name:'diciembre'}
                ];

  const selectMonths = Array.from(months).map((month, idx) => (
    <option key={month.number} value={month.number}>{month.name}</option>
  ));

  return (
    <div>
      <div className='ps-5 pe-5 background-nav'>
        <NavbarUser active='/misPedidos'></NavbarUser>
      </div>
      <Container className='Layout'>
        <h3><b>Mis <span className='green'>pedidos</span></b></h3>
        { !session.isLoggedIn &&
          <div>
            <p><b>Inicia sesión para ver tu historial de pedidos. </b></p>
            <Button href='/login' className='SecondaryBtn'>Iniciar sesión</Button>
          </div>

        }

        { session.isLoggedIn &&
          <Row className='mt-5'>
            <Col>
              <Form.Select value={selectedYear} onChange={(e) => {setSelectedYear(e.currentTarget.value)}}>
                {selectYears}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select value={selectedMonth} onChange={(e) => {setSelectedMonth(e.currentTarget.value)}}>
              {selectMonths}
              </Form.Select>
            </Col>
          </Row>
        }
        { Object.keys(products).length === 0 &&
          <div className='mt-5'>
            <p><b>No tienes pedidos en la fecha seleccionada. </b></p>
            <Button href='/productos' className='SecondaryBtn'>Haz un pedido</Button>
          </div>
        }


        { session.isLoggedIn &&
          Object.keys(products).reverse().map((order, index) => {
            return(
              <div key={products[order][0].idOrder} className='mt-5'>
                <h4>Pedido del {new Date(products[order][0].dateTime).toLocaleDateString('es-MX', options)}</h4>
                {
                    products[order][0].isCanceled === 1 &&
                    <h5 className='text-danger'>Pedido cancelado <ion-icon size='small' name="close-circle-outline"/></h5>
                }
                {
                  !products[order][0].isCanceled && !products[order][0].isReceived && products[order][0].isOutForDelivery ?
                    <div>
                      <h5 className='inDelivery'>Pedido en camino <ion-icon size='small' name="bus-outline"/></h5>
                      {products[order][0].trackingGuide ? <h5 className='inDelivery'>Guía de rastreo: {products[order][0].trackingGuide}</h5> : ''}
                    </div>
                    : ''
                }
                {
                  !products[order][0].isCanceled && products[order][0].isReceived ?
                    <h5 className='green'>Pedido entregado <ion-icon size='small' name="checkmark-circle-outline"/></h5> : ''
                }
                {
                  !products[order][0].isCanceled && !products[order][0].isReceived && !products[order][0].isOutForDelivery ?
                    <h5 className='text-muted'>Estamos preparando tu pedido <ion-icon size='small' name="basket-outline"/></h5> : ''
                }
                {
                  products[order][0] && products[order][0].isReceived === 0 && products[order][0].isCanceled === 0 &&
                  <p className='text-muted'>Llega el {new Date(products[order][0].estimatedDelivery.substring(0,10) + " 12:00:00").toLocaleDateString('es-MX', options)}</p>
                }
                {
                  products[order][0] && products[order][0].isReceived === 1 && products[order][0].isCanceled === 0 &&
                  <p className='text-muted'>Entregado el {new Date(products[order][0].deliveryDatetime).toLocaleDateString()} a las {new Date(products[order][0].deliveryDatetime).toLocaleTimeString()}</p>
                }
                <p className='text-muted'>ID: {products[order][0].idOrder}</p>

                <Table responsive="sm">
                  <thead className='align-middle'>
                    <tr>
                      <th></th>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio unitario</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                {
                Object.keys(products[order]).map((product, index) => {
                  return(
                    <tr key={products[order][product].idProduct}>
                      <td>
                        <Image id={`${products[order][product].idProduct}${products[order][product].imgExtension}`} src={`${Environment()}/image${products[order][product].idImage}${products[order][product].imgExtension}`} style={{ width: "calc(50px + 3vw)" }}></Image>
                      </td>
                      <td>
                        <b>{products[order][product].name} {products[order][product].isPriceRecalculated  && !products[order][product].isOutForDelivery && !products[order][product].isReceived ? "*" : ''}</b>
                      </td>
                      <td>{products[order][product].isOutForDelivery || products[order][product].isReceived ? products[order][product].finalQuantity + ' ' : products[order][product].quantity * products[order][product].unitRelation + ' '}
                      {Number(products[order][product].quantity * products[order][product].unitRelation) <= 1 &&
                        UMs[products[order][product].idFinalUnit].singularName
                      }
                      {Number(products[order][product].quantity * products[order][product].unitRelation) > 1 && 
                        UMs[products[order][product].idFinalUnit].pluralName
                      }
                      {products[order][product].idUnit !==  products[order][product].idFinalUnit ? 
                        ' o ' +
                        products[order][product].quantity + ' ' +
                        (Number(products[order][product].quantity) <= 1 ?
                          products[order][product].singularName
                        : 
                          products[order][product].pluralName
                        )
                        
                        : ''}

                      {products[order][product].isPriceRecalculated && !products[order][product].isOutForDelivery && !products[order][product].isReceived ? " aprox." : ''} 
                      </td>
                      <td>
                        {
                          <span hidden={products[order][product].soldDiscount <= 0 || (!products[order][0].isReceived && !products[order][0].isOutForDelivery)} className='me-3 text-muted'><del>${(parseFloat(products[order][product].soldPrice)+parseFloat(products[order][product].soldDiscount)).toFixed(2)}</del></span>
                        }
                        ${products[order][product].soldPrice}/{UMs[products[order][product].idFinalUnit].singularName}
                      </td>
                      <td>
                        ${products[order][product].isOutForDelivery || products[order][product].isReceived ? (products[order][product].finalQuantity * products[order][product].soldPrice).toFixed(2) : (products[order][product].soldPrice * products[order][product].quantity * products[order][product].unitRelation).toFixed(2)}
                        {products[order][product].isPriceRecalculated  && !products[order][product].isOutForDelivery && !products[order][product].isReceived ? " aprox." : ''} 
                      </td>
                    </tr>
                  );
                })
              }
              {products[order].length > 0 &&
              <tr>
                <td colSpan={5} className='text-end'>
                  <small>{Array.from(products[order]).reduce((partialSum, product) => partialSum + (product.isPriceRecalculated? 1 : 0), 0) > 0 && !products[order][0].isOutForDelivery && !products[order][0].isReceived ? '* Este producto se pesa antes de su entrega, lo cual puede provocar variaciones en el precio final.' : ''}</small>
                </td>
              </tr>}
              {(products[order].length > 0 && !products[order][0].isOutForDelivery && !products[order][0].isReceived) ?
              <tr>
                <td colSpan={5} className='text-end'><h5>Total: <b>${Array.from(products[order]).reduce((partialSum, product) => partialSum + (product.soldPrice*product.quantity*product.unitRelation), 0).toFixed(2)}</b> {Array.from(products[order]).reduce((partialSum, product) => partialSum + ((product.isPriceRecalculated)? 1 : 0), 0) > 0 && !products[order][0].isOutForDelivery && !products[order][0].isReceived ? 'aprox.' : ''}</h5></td>
              </tr>
              :
              <tr>
                <td colSpan={5} className='text-end'><h5>Total: <b>${Array.from(products[order]).reduce((partialSum, product) => partialSum + (product.soldPrice*product.finalQuantity), 0).toFixed(2)}</b> {Array.from(products[order]).reduce((partialSum, product) => partialSum + ((product.isPriceRecalculated)? 1 : 0), 0) > 0 && !products[order][0].isOutForDelivery && !products[order][0].isReceived ? 'aprox.' : ''}</h5></td>
              </tr>
              }
              </tbody>
            </Table>
            </div>
            );
          })
        }
      </Container>
      <FooterUser active='/misPedidos'></FooterUser>
    </div>
  );
}

export default MyOrders;
