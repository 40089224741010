import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './views/home';
import LogIn from './views/logIn';
import Products from './views/products';
import AdminProducts from './views/adminProducts';
import AdminPictures from './views/adminPictures';
import AdminFolderPictures from './views/adminFolderPictures';
import AdminUsers from './views/adminUsers';
import Registro from './views/register';
import MyAccount from './views/myaccount';
import AboutUs from './views/AboutUs';
import PrivacyNotice from './views/PrivacyNotice';
import Cart from './views/cart';
import Orders from './views/orders';
import Order from './views/order';
import MyOrders from './views/myOrders';
import AdminSuppliers from './views/adminSuppliers';
import AdminHome from './views/adminHome';
import SuppliersOrders from './views/suppliersOrders';
import SupplierOrder from './views/supplierOrder';
import ProductInventory from './views/productInventory';
import ProductDetails from './views/productDetails';
import DeliverOrders from './views/deliverOrders';
import OrderDelivery from './views/orderDelivery';
import BalanceSheet from './views/balanceSheet';
import StatementOfIncome from './views/statementOfIncome';
import Metrics from './views/metrics';
import ForgotMyPassword from './views/forgotMyPassword';
import ResetMyPassword from './views/resetMyPassword';
import ModifyPassword from './views/modifyPassword';
import DeniedAccess from './views/deniedAccess';
import Post from './views/post';
import RegisterUser from './views/registerUser';
import ModifyClient from './views/modifyClient';

import { ProtectedRoute } from './utils/protected';

function App() {

  const management = ['Gerencia'];
  const coordinator = ['Coordinación', 'Gerencia'];
  const delivery = ['Repartidor', 'Coordinación', 'Gerencia'];
  const assistant = ['Auxiliar', 'Repartidor', 'Coordinación', 'Gerencia'];


  return (
    <BrowserRouter>
            <Routes>
                <Route path='*' element={<Navigate to='/inicio' />} />
                <Route exact path="/login" element={LogIn()} />
                <Route exact path="/inicio" element={Home()} />
                <Route exact path="/AboutUs" element={AboutUs()} />
                <Route exact path="/aviso-privacidad" element={PrivacyNotice()} />
                <Route exact path="/productos" element={<Products/>} />
                <Route exact path="/productos/:category" element={<Products/>} />
                <Route exact path="/registro" element={<Registro/>} />
                <Route exact path="/carrito" element={<Cart/>} />
                <Route exact path="/micuenta" element={<MyAccount/>} />
                <Route exact path="/misPedidos" element={<MyOrders/>} />
                <Route exact path="/productoDetallado/:idProduct" element={<ProductDetails/>} />
                <Route exact path="/recuperarCuenta" element={<ForgotMyPassword/>} />
                <Route exact path="/cambiarContrasena" element={<ModifyPassword/>} />
                <Route exact path="/restaurarCuenta/:email/:token" element={<ResetMyPassword/>} />
                <Route exact path="/deniedaccesspage" element={<DeniedAccess/>} />
                <Route exact path="/post/:idPost" element={<Post/>} />

                {/* Auxiliar */}

                <Route 
                  exact 
                  path="/administracion" 
                  element={
                    <ProtectedRoute authorizedRoles={assistant}>
                      <AdminHome role={assistant}/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/pedidos/:idOrder" 
                  element={
                    <ProtectedRoute authorizedRoles={assistant}>
                      <Order/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/pedidos" 
                  element={
                    <ProtectedRoute authorizedRoles={assistant}>
                      <Orders/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/inventario" 
                  element={
                    <ProtectedRoute authorizedRoles={assistant}>
                      <ProductInventory/>
                    </ProtectedRoute>
                  } 
                />

                {/* Repartidor */}

                <Route 
                  exact 
                  path="/entregarProductos/:checked" 
                  element={
                    <ProtectedRoute authorizedRoles={delivery}>
                      <DeliverOrders/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/entregarPedido" 
                  element={
                    <ProtectedRoute authorizedRoles={delivery}>
                      <OrderDelivery/>
                    </ProtectedRoute>
                  } 
                />

                {/* Coordinación */}

                <Route 
                  exact 
                  path="/gestionarproductos" 
                  element={
                    <ProtectedRoute authorizedRoles={coordinator}>
                      <AdminProducts/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/gestionarimagenes" 
                  element={
                    <ProtectedRoute authorizedRoles={coordinator}>
                      <AdminPictures/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/gestionarimagenes/:idFolder" 
                  element={
                    <ProtectedRoute authorizedRoles={coordinator}>
                      <AdminFolderPictures/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/metricas" 
                  element={
                    <ProtectedRoute authorizedRoles={coordinator}>
                      <Metrics/>
                    </ProtectedRoute>
                  } 
                />

                {/* Gerencia */}

                <Route 
                  exact 
                  path="/gestionarusuarios" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <AdminUsers/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/nuevousuario" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <RegisterUser/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/modificarusuario" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <ModifyClient/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/proveedores" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <AdminSuppliers/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/pedidosproveedores" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <SuppliersOrders/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/pedidosproveedores/:idOrder" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <SupplierOrder/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/balanceGeneral" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <BalanceSheet/>
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  exact 
                  path="/estadoDeResultados" 
                  element={
                    <ProtectedRoute authorizedRoles={management}>
                      <StatementOfIncome/>
                    </ProtectedRoute>
                  } 
                />             

            </Routes>
      </BrowserRouter>
  );
}

export default App;


