import NavbarUser from '../components/navbar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import FooterUser from '../components/footerUser';

function PrivacyNotice() {

    return (
        <div>
                <NavbarUser active='/AboutUs'></NavbarUser>
                <Container className='mb-5'>
                    <h3 className="mt-5 mb-5 text-center"><b>Aviso de privacidad</b></h3>                        
                    <p className="justify">Itzia Ochoa Loyola, mejor conocida como Terra Viva, con domicilio en calle Parcela 179A, colonia Ejido Escolásticas, ciudad Pedro Escobedo, municipio o delegación Pedro Escobedo, C.P. 76746, en la entidad de Querétaro, país México, y portal de internet terraviva.org.mx, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:</p>
                    <p className="justify"><b>¿Para qué fines utilizaremos sus datos personales?</b></p>
                    <p className="justify">Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
                    <Container>
                        <p className="justify"><b>Datos personales de clientes</b></p>
                        <ul>
                            <li>Su correo electrónico se utilizará para enviar códigos referentes a la configuración de su cuenta de usuario como cambio de contraseña o recuperación de la cuenta de usuario.</li>
                            <li>Su constancia de situación fiscal será utilizada para facturar.</li>
                            <li>Su nombre, dirección y ubicación serán utilizados para poder realizar envíos a domicilio en caso de levantar un pedido a Terra Viva.</li>
                            <li>Su historial crediticio se utilizará para aprobar o denegar créditos.</li>
                        </ul>
                    </Container>
                    <Container>
                        <p className="justify"><b>Datos personales de empleados</b></p>
                        <ul>
                            <li>Su nombre completo, identificación oficial, comprobante de domicilio, acta de nacimiento, CURP, currículum y cuenta bancaria se utilizarán para asignar roles y permisos a su cuenta de usuario, generar su nómina y generar su expediente de trabajador.</li>
                        </ul>
                    </Container>
                    <Container>
                        <p className="justify"><b>Datos personales de proveedores</b></p>
                        <ul>
                            <li>Su nombre, empresa y constancia de situación fiscal se utilizarán para identificar la persona a la que nos dirigiremos para levantar pedidos y corroborar que está dado de alta ante el SAT con los datos que nos proporcionó en su constancia de situación fiscal.</li>
                        </ul>
                    </Container>
                    <Container>
                        <p className="justify"><b>Otros (terceros)</b></p>
                        <ul>Entrega de información ante una revisión de las autoridades.</ul>
                    </Container>
                    <ul>
                        <li>Mercadotecnia o publicitaria</li>
                        <li>Prospección comercial</li>
                    </ul>
                    <p className="justify"><b>¿Qué datos personales utilizaremos para estos fines?</b></p>
                    <p className="justify">Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
                    <ul>
                        <li>Nombre</li>
                        <li>Estado Civil</li>
                        <li>Registro Federal de Contribuyentes(RFC)</li>
                        <li>Clave única de Registro de Población (CURP)</li>
                        <li>Lugar de nacimiento</li>
                        <li>Fecha de nacimiento</li>
                        <li>Nacionalidad</li>
                        <li>Domicilio</li>
                        <li>Teléfono particular</li>
                        <li>Teléfono celular</li>
                        <li>Correo electrónico</li>
                        <li>Firma autógrafa</li>
                        <li>Edad</li>
                        <li>Fotografía</li>
                        <li>Tipo de sangre</li>
                        <li>Puesto o cargo que desempeña</li>
                        <li>Domicilio de trabajo</li>
                        <li>Correo electrónico institucional</li>
                        <li>Referencias laborales</li>
                        <li>Información generada durante los procesos de reclutamiento, selección y contratación</li>
                        <li>Capacitación laboral</li>
                        <li>Trayectoria educativa</li>
                        <li>Títulos</li>
                        <li>Cédula profesional</li>
                        <li>Certificados</li>
                        <li>Reconocimientos</li>
                        <li>Información fiscal</li>
                        <li>Historial crediticio</li>
                        <li>Cuentas bancarias</li>
                        <li>Número de tarjetas de crédito</li>
                        <li>Afores</li>
                        <li>Pasatiempos</li>
                        <li>Aficiones</li>
                        <li>Ubicación</li>
                    </ul>
                    <p className="justify">Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protección:</p>
                    <ul>
                        <li>Información genética</li>
                    </ul>
                    <p className="justify"><b>¿Con quién compartimos su información personal y para qué fines?</b></p>
                    <p className="justify"></p>
                    <p className="justify">Le informamos que sus datos personales son compartidos dentro del país con las siguientes personas, empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines:</p>
                    <Table className='mt-5 mb-5' bordered responsive>
                        <tr>
                            <th>Destinatario de los datos personales</th>
                            <th>Finalidad</th>
                            <th>Requiere del consentimiento</th>
                        </tr>
                        <tr>
                            <td>Empresa de paquetería</td>
                            <td>Envío de pedidos a domicilio</td>
                            <td>Sí</td>
                        </tr>
                        <tr>
                            <td>Autoridades</td>
                            <td>
                                <ul>
                                    <li>Registro de un empleado ante el SAT e IMSS.</li>
                                    <li>Facturación a clientes.</li>
                                    <li>Informar al SAT y a las autoridades las compras y ventas realizadas por Terra Viva.</li>
                                </ul>
                            </td>
                            <td>Sí</td>
                        </tr>
                        <tr>
                            <td>Otros</td>
                            <td>Entrega de información ante una revisión de las autoridades.</td>
                            <td>Sí</td>
                        </tr>
                    </Table>
                    <p className="justify">Con relación a las transferencias que requieren su consentimiento, ponemos a su disposición el siguiente mecanismo para que, en su caso, nos lo otorgue. Le recordamos que si después de leer este aviso de privacidad no nos otorga su consentimiento, dichas transferencias no se podrán realizar:</p>
                    <p className="justify">En la página web terraviva.org.mx al crear su cuenta de usuario.</p>
                    <p className="justify"><b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</b></p>
                    <p className="justify">Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
                    <p className="justify">Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:</p>
                    <p className="justify">Sus datos se pueden acceder, modificar y eliminar a través del sitio web terraviva.org.mx o contactándonos a través de WhatsApp al número 442 111 1114.</p>
                    <p className="justify">Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el siguiente medio:</p>
                    <p className="justify">A través de WhatsApp enviando mensaje al número 442 111 1114 o enviando un correo electrónico a ventas@terraviva.org.mx</p>
                    <p className="justify">Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>
                    <ol type='a'>
                        <li>Nombre de la persona o departamento de datos personales: Itzia Ochoa Loyola</li>
                        <li>Domicilio: calle Parcela 179A, colonia Ejido Escolásticas, ciudad Pedro Escobedo, municipio o delegación Pedro Escobedo, c.p. 76746, en la entidad de Querétaro, país México</li>
                        <li>Correo electrónico: ventas@terraviva.org.mx</li>
                    </ol>
                    <p className="justify"><b>Usted puede revocar su consentimiento para el uso de sus datos personales</b></p>
                    <p className="justify">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal queramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
                    <p className="justify">Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:</p>
                    <p className="justify">A través de WhatsApp, enviando mensaje al número 442 111 1114 o un correo electrónico a ventas@terraviva.org.mx</p>
                    <p className="justify">Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el siguiente medio:</p>
                    <p className="justify">A través de WhatsApp, enviando mensaje al número 442 111 1114 o un correo electrónico a ventas@terraviva.org.mx</p>
                    <p className="justify"><b>¿Cómo puede limitar el uso o divulgación de su información personal?</b></p>
                    <p className="justify">Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:</p>
                    <p className="justify">A través de WhatsApp, enviando mensaje al número 442 111 1114 o un correo electrónico a ventas@terraviva.org.mx</p>
                    <p className="justify">De manera adicional, le informamos que contamos con los siguientes listados de exclusión, en los cuales podrá registrarse para que sus datos personales no sean tratados para ciertos fines:</p>
                    <Table className='mt-5 mb-5' bordered responsive>
                        <tr>
                            <th>Nombre del listado</th>
                            <th>Finalidad para las que aplica</th>
                            <th>Medio para obtener mayor información</th>
                        </tr>
                        <tr>
                            <td>Listado de exclusión</td>
                            <td>Retirar algún dato personal de su cuenta de usuario o eliminar su cuenta.</td>
                            <td>Correo electrónico o WhatsApp</td>
                        </tr>
                    </Table>
                    <p className="justify">Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que no desee obtener publicidad de nuestra parte:</p>
                    <ul>
                        <li>Registro Público para Evitar Publicidad, para mayor información consulte el portal de internet de la PROFECO</li>
                        <li>Registro Público de Usuarios, para mayor información consulte el portal de internet de la CONDUSEF</li>
                    </ul>
                    <p className="justify"><b>El uso de tecnologías de rastreo en nuestro portal de internet</b></p>
                    <p className="justify">Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:</p>
                    <ul>
                        <li>Para brindarle al usuario una experiencia personalizada (el idioma de su preferencia)</li>
                        <li>Para mantener una sesión de usuario activa</li>
                    </ul>
                    <p className="justify">Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
                    <ul>
                        <li>Identificadores, nombre de usuario y contraseñas de una sesión</li>
                        <li>Idioma preferido por el usuario</li>
                        <li>Región en la que se encuentra el usuario</li>
                    </ul>
                    <p className="justify"><b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b></p>
                    <p className="justify">El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                    <p className="justify">Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: Correo electrónico.</p>
                    <p className="justify">El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:</p>
                    <p className="justify">Se enviará un correo electrónico con el nuevo aviso de privacidad cada que tenga cambios o actualizaciones.</p>
                </Container>
                <FooterUser active='/AboutUs'></FooterUser>
        </div>
        
    );
}

export default PrivacyNotice;
