import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Environment from './../views/Environment';
import NewProductModal from './newProductModal';
import ModifyProductModal from './modifyProductModal';
import DeleteProductModal from './deleteProductModal';
import HideProductModal from './hideProductModal';
import './components.css';

function ProductTable(){
    const [products, setProducts] = useState([]);
    const [productsSearch, setProductsSearch] = useState([]);
    const [modalShowNew, setModalShowNew] = useState(false);
    const [modalShowModify, setModalShowModify] = useState(false);
    const [modalShowDelete, setModalShowDelete] = useState(false);
    const [modalShowHide, setModalShowHide] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedPicture, setSelectedPicture] = useState(0);
    const [deletedProductName, setDeletedProductName] = useState(0);
    const [deletedProductId, setDeletedProductId] = useState(0);
    const [hideProductName, setHideProductName] = useState(0);
    const [hideProductId, setHideProductId] = useState(0);
    const [hideProductIsHidden, setHideProductIsHidden] = useState(0);
    const [categoryModify, setCategoryModify] = useState(null);
    const [UMs, setUMs] = useState([]);

    const handleClose = () => setShow(false);
    function handleShow(e){
        setShow(true);
        setSelectedPicture(e.currentTarget.id);
    }

    function handleShowModify(e){
        setCategoryModify(e.currentTarget.id);
        setModalShowModify(true);
    }

    function Delete(e){
        setModalShowDelete(true);
        setDeletedProductName(e.currentTarget.name);
        setDeletedProductId(e.currentTarget.id);
    }

    function Hide(idProduct, name, isHidden){
        setModalShowHide(true);
        setHideProductName(name);
        setHideProductId(idProduct);
        setHideProductIsHidden(isHidden);
    }

    function filterProducts(e){
        e.preventDefault();
        const product = e.currentTarget.value;
        if(!product){
            setProductsSearch('');
        }
        else{
            setProductsSearch(products?.filter((row) => 
                                row?.name?.match(new RegExp(product, "i")) ||
                                row?.categoryName?.match(new RegExp(product, "i")) ||
                                row?.supplierName?.match(new RegExp(product, "i"))

            ));
        }
    }

    const arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
          obj[item[keyField]] = item;
          return obj;
        }, {});

    useEffect(() => {
        fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    useEffect(() => {
        fetch(`${Environment()}/products/getProducts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setProducts(data);
        })
        .catch(err => {
            console.error(err);
        });
    }, [])

    const months = 
    {1:'enero', 
    2:'febrero',
    3:'marzo',
    4:'abril',
    5:'mayo',
    6:'junio',
    7:'julio',
    8:'agosto',
    9:'septiembre',
    10:'octubre',
    11:'noviembre',
    12:'diciembre'
    };

    const productRows = Array.from((productsSearch.length < 1) ? products : productsSearch).map((product, idx) => (
        <tr key={product.idProduct}>
          <td>{product.name}</td>
          <td>{product.categoryName}</td>
          <td><Image id={`${product.idImage}${product.imgExtension}`} onClick={(e) => {handleShow(e)}} style={{ width: "calc(50px + 3vw)" }} src={`${Environment()}/image${product.idImage}${product.imgExtension}`}></Image></td>
          <td>{product.singularName}</td>
          <td>{UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</td>
          <td>{product.isPriceRecalculated ? 'Si' : 'No'}</td>
          <td> 1 {product.singularName} : {product.unitRelation} {UMs[product.idFinalUnit] ? UMs[product.idFinalUnit].singularName : ''}</td>
          <td>${(parseFloat(product.price) + parseFloat(product.discount)).toFixed(2)}</td>
          <td>${product.discount}</td>
          <td>${product.cost}</td>
          <td>{product.supplierName}</td>
          <td>{product.shortDescription}</td>
          <td>{product.longDescription}</td>
          <td>{product.seasonStart && product.seasonEnd && product.seasonStart.split(",").length > 1 && product.seasonEnd.split(",").length > 1? product.seasonStart.split(",")[0] + " " + months[product.seasonStart.split(",")[1]] + " al " + product.seasonEnd.split(",")[0] + " " + months[product.seasonEnd.split(",")[1]] : ""}</td>
          <td className='text-center'><Button data-cy={product.name + ' modify'} id={product.idProduct} className='SecondaryBtn' onClick={(e) => handleShowModify(e)}><ion-icon name="pencil-sharp"></ion-icon></Button></td>
          <td className='text-center'><Button data-cy={product.name + ' delete'} id={product.idProduct} name={product.name} onClick={(e) => {Delete(e)}} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
          <td className='text-center'><Button onClick={() => {Hide(product.idProduct, product.name, product.isHidden)}} variant="outline-primary">{product.isHidden === 1? <ion-icon name="eye-outline"/>: <ion-icon name="eye-off-outline"/>}</Button></td>
        </tr>                     
    ));

    return(
        <Container>
            <Table>
                <thead>
                    <tr>
                        <td><Form.Control
                            placeholder="Buscar producto"
                            className="me-4"
                            style={{width:'auto'}}
                            onChange={(e) => {filterProducts(e)}}
                            />
                        </td>
                        <td colSpan={10} className='text-end'><Button href='/gestionarimagenes' className='PrimaryBtn me-2'> <ion-icon className='Icon' slot="start" name="images-outline"/> Galería</Button><Button data-cy="Create product" onClick={() => setModalShowNew(true)} className='PrimaryBtn'><ion-icon className='Icon' slot="start" name="add-outline"/> Crear producto</Button></td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Categoría</th>
                        <th>Imagen</th>
                        <th>UM pedido</th>
                        <th>UM venta</th>
                        <th>Precio recalculable</th>
                        <th>Relación UM</th>
                        <th>Precio sin descuento</th>
                        <th>Descuento</th>
                        <th>Costo</th>
                        <th>Proveedor</th>
                        <th>Desc corta</th>
                        <th>Desc larga</th>
                        <th>Temporada</th>
                        <th>Modificar</th>
                        <th>Eliminar</th>
                        <th>Ocultar/ Mostrar</th>
                    </tr>
                </thead>
                <tbody>
                    {productRows}
                </tbody>
            </Table>
            <NewProductModal
            show={modalShowNew}
            onHide={() => setModalShowNew(false)}
            title='Crear producto'
            description='Campos obligatorios *'
            />
            <ModifyProductModal
            show={modalShowModify}
            onHide={() => setModalShowModify(false)}
            title='Modificar Producto'
            description=''
            id={categoryModify}
            />
            <DeleteProductModal
            show={modalShowDelete}
            onHide={() => setModalShowDelete(false)}
            name={deletedProductName}
            id={deletedProductId}
            />
            <HideProductModal
            show={modalShowHide}
            onHide={() => setModalShowHide(false)}
            name={hideProductName}
            id={hideProductId}
            action={hideProductIsHidden}
            />
            <Modal className='d-flex justify-content-center' centered size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <Image
                fluid 
                style={{ width: "calc(50px + 40vw)" }} 
                src={`${Environment()}/image${selectedPicture}`}>
                </Image>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default ProductTable;