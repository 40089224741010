import React, { useState } from 'react';
import './components.css';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Environment from '../views/Environment';
import ModalSeason from './modalSeason';

function CardProductSmall(props) {

    const [modalShowSeason, setModalShowSeason] = useState(false);

    const handleClose = () => setModalShowSeason(false);
    const handleOpen = () => setModalShowSeason(true);
    
    return (
        <Card className="mb-3 shadow bg-white rounded">
            <div className="text-black text-decoration-none transparent">
                <Row className='mt-3 mb-3 ms-1 me-1'>
                    <Col>
                        <a href={`http://localhost:3000/productoDetallado/${props.id}`} className="text-black text-decoration-none transparent">
                            <Image sizes='small' width={150} className={`small-card-img-top ${!props.isSeason || !props.isInStock? "blur-container" : ""}`} src={`${Environment()}/image${props.idImage}${props.imgExtension}`} alt={props.name}  />
                        </a>
                    </Col>
                    <Col>
                        <a href={`http://localhost:3000/productoDetallado/${props.id}`} className="text-black text-decoration-none transparent">
                            <h6><strong>{props.name} </strong></h6>
                            <p className="text-muted"><small>{props.category}</small></p>
                            <h6 className="text-muted"><small>{props.shortDescription}</small></h6>
                            {
                                parseFloat(props.discount) > 0 &&
                                <h6 className='text-muted'><del>{`${"$"}${(parseFloat(props.discount) + parseFloat(props.price)).toFixed(2)}`}</del></h6>
                            }
                            <h6><strong>{`${"$"}${props.price}/${props.finalUnit}`}</strong></h6>
                        </a>
                        <div className='row text-center mt-3' hidden={props.hiddeBuyButton === "true"}>
                            {props.isSeason ? (props.isInStock ?
                                ""
                                :
                                <Button size='sm' disabled className='btn-danger'><small> Agotado</small></Button>)
                                :
                                <Button size='sm' className='btn-secondary' onClick={() => handleOpen()}><small> Fuera de temporada</small></Button>
                            } 
                        </div>
                    </Col>
                </Row>
            </div>
            <ModalSeason
                onHide={() => handleClose()}
                show={modalShowSeason}
            />
        </Card>
    )
}

export default CardProductSmall;