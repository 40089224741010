import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Environment from './../views/Environment';
import './views.css';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import NavbarUser from '../components/navbar';
import FooterUser from '../components/footerUser';


function ProductDetails() {
    const [fetchProduct, setFetchProduct] = useState({});
    const [productImages, setProductImages] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    let { idProduct } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [UMs, setUMs] = useState([]);
    const [UM, setUM] = useState('');

    const arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
          obj[item[keyField]] = item;
          return obj;
        }, {});
  
      useEffect(() => {
        fetch(`${Environment()}/unitmeasures/getUnitMeasures`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
          setUMs(arrayToObject(data, 'idUnit'));
        })
        .catch(err => {
            console.error(err);
        });
      }, [])

    useEffect(() => {
        if(quantity === '1' || quantity === 1){
            setUM(fetchProduct.singularName);
        } else{
            setUM(fetchProduct.pluralName);
        }
    }, [fetchProduct, quantity])
    
    useEffect(() => {
        fetch(`${Environment()}/products/getProductById/${idProduct}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
            
        })
            .then(data => data.json())
            .then((data) => {
                if(data.isHidden === 1 || data.length === 0){
                    setError('¡Ups! Este producto ya no está diponible.');
                }
                else{
                    setFetchProduct(data);
                }
            })
            .catch(err => {
                setError('¡Ups! Este producto ya no está diponible.');
                console.error(err);
            });
    }, [idProduct])

    useEffect(() => {
        fetch(`${Environment()}/products/getProductImages/${idProduct}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
            
        })
            .then(data => data.json())
            .then((data) => {
                setProductImages(data)
            })
            .catch(err => {
                console.error(err);
            });
    }, [idProduct])

    function convertVideoURL(string){
        if(string){
          let video = '';
          video = string.split('/');
            if(video.length === 4){
                video = video[3].split('?')[0]
            } else {
                video  = '';
            }
      
          return video;
        }
    }

    const Images = Array.from(productImages).map((image, idx) => (
        <Col key={image.idImage}>
            <img hidden={image.isVideo} onClick={(e) => setCurrentImage(idx)} style={{ width: "calc(10px + 13vw)" }} src={`${Environment()}/image${image.idImage}${image.imgExtension}`} alt={image.name} />
            <img hidden={!image.isVideo} onClick={(e) => setCurrentImage(idx)} style={{ width: "calc(10px + 13vw)" }} src={`https://img.youtube.com/vi/${convertVideoURL(image.video)}/hqdefault.jpg`} title="YouTube video player" alt={image.name}/>
        </Col>                      
    ));

    function addToCart(e){
        e.preventDefault();
        fetch(`${Environment()}/login/isLoggedIn`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(sessionData => sessionData.json())
        .then((sessionData) => {
            if(sessionData.isLoggedIn){
                fetch(`${Environment()}/orders/getCart/${sessionData.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(cart => cart.json())
                .then((cart) => {
                    fetch(`${Environment()}/orders/getCartProducts/${cart.idOrder}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            }
                    })
                    .then(productsData => productsData.json())
                    .then((productsData) => {
                        const result = productsData.reduce((acc, item) => {
                            acc[item.idProduct] = item;
                            return acc;
                        }, {});
                        if(Number(fetchProduct.quantity) < Number(quantity) + (result[idProduct] ? Number(result[idProduct].quantity) : 0)){
                            setError('Solamente tenemos ' + Number(fetchProduct.quantity) + ' ' + (Number(fetchProduct.quantity) > 1 ? fetchProduct.pluralName : fetchProduct.singularName) + ' de ' + fetchProduct.name + '. Escribe una cantidad más pequeña.');
                            return;
                        }
                        fetch(`${Environment()}/orders/postCartProduct`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                quantity:quantity,
                                idProduct: idProduct,
                                idOrder:cart.idOrder,
                                soldPrice:fetchProduct.price,
                                soldDiscount:fetchProduct.discount
                            })
                        
                        })
                        .then(() => {
                            setError('');
                            setSuccess('Producto añadido exitosamente.');
                            setTimeout(function () {
                                setSuccess('');
                            }, 3000);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                    })
                    .catch(err => {
                        console.error(err);
                    });
                })
                .catch(err => {
                    console.error(err);
                });
            }else{
                setError('Inicia sesión para añadir productos al carrito.');
            }
        })
    }

    return (         
        <div>
            <Row className='ps-5 pe-5 background-nav'>
                <NavbarUser active='/productos'></NavbarUser>
            </Row>
            <Row className='ps-5 pe-5 Layout'>
                <Col>
                    {
                        productImages[currentImage] &&
                        <div>
                            {productImages[currentImage].isVideo === 1 &&
                                <div>
                                    <iframe hidden={productImages[currentImage].isVideo === 1} className='d-block d-sm-none' style={{ width: "calc(10px + 75vw)" }} crossOrigin="anonymous" src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(productImages[currentImage].video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    <iframe hidden={productImages[currentImage].isVideo === 0} className='d-none d-sm-block' style={{ width: "calc(10px + 50vw)", height: "calc(10px + 30vw)" }} crossOrigin="anonymous" src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(productImages[currentImage].video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            }

                            {productImages[currentImage].isVideo === 0 &&
                                <div>
                                    <img hidden={productImages[currentImage].isVideo === 0} className='d-block d-sm-none' style={{ width: "calc(10px + 75vw)" }} src={`${Environment()}/image${productImages[currentImage].idImage}${productImages[currentImage].imgExtension}`} alt={productImages[currentImage].name} />
                                    <img hidden={productImages[currentImage].isVideo === 1} className='d-none d-sm-block' style={{ width: "calc(10px + 40vw)" }} src={`${Environment()}/image${productImages[currentImage].idImage}${productImages[currentImage].imgExtension}`} alt={productImages[currentImage].name} />
                                </div>
                            }
                        </div>
                    }
                    <Row xs={3} className="mt-3 g-4 mb-5">
                        {Images}
                    </Row>
                </Col>       

                <Col>
                    <h2><b>{fetchProduct.name}</b></h2>
                    <p className="text-muted">{fetchProduct.shortDescription}</p>
                    <p>{fetchProduct.longDescription}</p>
                    
                    <Form onSubmit={(e) => {addToCart(e)}}>
                        <Row>
                            {parseFloat(fetchProduct.discount) > 0 &&
                                <Col xs={5} lg={3}>
                                        <h3 className='text-muted'><del>{`${"$"}${(parseFloat(fetchProduct.discount) + parseFloat(fetchProduct.price)).toFixed(2)}`}</del></h3>
                                </Col>
                            }
                            <Col>
                                <h3>${fetchProduct.price}/{UMs[fetchProduct.idFinalUnit] ? UMs[fetchProduct.idFinalUnit].singularName : ''}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='text-center mt-3'>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            disabled={!fetchProduct.isSeason || !fetchProduct.isInStock}
                                            
                                            value={quantity}
                                            type="number"
                                            step={fetchProduct.isFloat? '0.1' : '1'}
                                            min={fetchProduct.isFloat? '0.1' : '1'}
                                            onChange={(e) => setQuantity(e.currentTarget.value)}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col className='text-start mt-3 '>
                                <h4 className="text-muted">{UM}</h4>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row text-center mt-3'>
                                {fetchProduct.isSeason ? (fetchProduct.isInStock ?
                                    <Button type="submit" className='BuyButton'><ion-icon name="cart-outline"></ion-icon><small> Añadir al carrito</small></Button>
                                    :
                                    <Button disabled className='btn-danger'><small> Agotado</small></Button>)
                                    :
                                    <Button disabled className='btn-secondary'><small> Fuera de temporada</small></Button>
                                }
                            </div>
                        </Row>
                    </Form>
                    {success &&
                        <div className='text-center mb-3 mt-2'>
                            <span className='text-success'><ion-icon size='large' name="checkmark-circle-sharp"></ion-icon></span>
                            <p className='text-success'>{success}</p>
                            <Button href='/carrito' className='SecondaryBtn'>Ir al carrito</Button>
                        </div>
                    }
                    <p className='text-danger'>{error}</p>
                </Col>
            </Row>
            <FooterUser active='/productos'/>
        </div>
                    
    );
}

export default ProductDetails;