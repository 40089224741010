import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import AdminBar from '../components/AdminBar';
import Pagination from 'react-bootstrap/Pagination';
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/esm/Card';
import Row from "react-bootstrap/esm/Row";
import NewImageModal from '../components/newImageModal';
import DeleteFolderModal from '../components/deleteFolderModal';
import Environment from './Environment';
import './views.css';

function AdminFolderPictures() {
  const [modalShowNew, setModalShowNew] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState({});
  const [folder, setFolder] = useState('');
  const [activeChecks, setActiveChecks] = useState(0);
  const [images, setImages] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editImage, setEditImage] = useState({});
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(1);
  let { idFolder } = useParams();
  const cardsPerPage = 12;

  /**
   * updateForm
   * @description updates data of a form
   * @param value: new values of the form
   * @returns an updated form
   */
  function updateForm(value) {
    return setEditImage((prev) => ({ ...prev, ...value }));
  }

  useEffect(() => {
    fetch(`${Environment()}/images/getFolderById/${idFolder}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setFolder(data[0]);
    })
    .catch(err => {
        console.error(err);
    });
  }, [idFolder])

  function ModifyImage(e){
    e.preventDefault()
    fetch(`${Environment()}/images/editImage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editImage)
    })
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
  }

  function DeleteImages(e){
    e.preventDefault()
    fetch(`${Environment()}/images/deleteImages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'checkBoxes':checkBoxes})
    })
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
        console.error(err);
    });
  }

  useEffect(() => {
    fetch(`${Environment()}/images/getPages/${idFolder}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      if(data[0].results > 0)
        setPages(Math.ceil(data[0].results / cardsPerPage)); 
    })
    .catch(err => {
        console.error(err);
    });
  }, [idFolder])

  useEffect(() => {
    fetch(`${Environment()}/images/getImages/${cardsPerPage}/${active}/${idFolder}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(data => data.json())
    .then((data) => {
      setImages(data);
    })
    .catch(err => {
        console.error(err);
    });
  }, [cardsPerPage, active, idFolder])

  const items = Array.from(Array(pages).keys()).map((page, idx) => (
    <Pagination.Item 
    onClick={() => {
        setActive(page+1);
        window.scroll({
            top: 50,
            behavior: 'smooth'
        });
    }} 
    key={page+1} 
    active={page+1 === active}>
        {page+1}
    </Pagination.Item>
  ));

  function convertVideoURL(string){
    let video = '';
    video = string.split('/');
      if(video.length === 4){
          video = video[3].split('?')[0]
      } else {
          video  = '';
      }

    return video;
  }

  const imageRows = Array.from(images).map((image, idx) => (
    <Col key={idx}>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Button 
                className='PrimaryBtn'
                disabled={editImage.edit !== image.idImage && editMode}
                hidden={editImage.edit === image.idImage && editMode}
                onClick={ e => {setEditMode(!editMode); setEditImage({name:image.name, scientificName:image.scientificName, edit:image.idImage});}}
              >
                <ion-icon 
                name="pencil" 
                />
              </Button>
              <Button 
                className='PrimaryBtn'
                hidden={editImage.edit !== image.idImage || !editMode}
                onClick={ e => {ModifyImage(e)}}
              >
                <ion-icon 
                name="checkmark" 
                />
              </Button>
            </Col>
            <Col className='text-end me-2'>
              <Form.Check
                disabled={image.linkedProducts > 0 || image.linkedCategories > 0}
                type='checkbox'
                id={image.idImage}
                onChange={e => 
                  {setCheckBoxes({...checkBoxes, [e.target.id]:!checkBoxes[e.target.id]}); 
                  checkBoxes[e.target.id] ? setActiveChecks(activeChecks + 1) : setActiveChecks(activeChecks - 1)}
                }
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          
          <Card.Img hidden={image.isVideo} className='mb-5 card-img-top' src={`${Environment()}/image${image.idImage}${image.imgExtension}`}/>                
          <iframe hidden={!image.isVideo} crossOrigin="anonymous" className='mb-5 card-img-top' src={`https://www.youtube-nocookie.com/embed/${convertVideoURL(image.video)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <Card.Text hidden={editImage.edit === image.idImage} className='text-center'><b>{image.name}</b></Card.Text>
          <Card.Text hidden={editImage.edit === image.idImage} className='text-center'><i>{image.scientificName}</i></Card.Text>
          <Form.Label hidden={editImage.edit !== image.idImage}><b>Nombre común *</b></Form.Label>
          <Form.Control 
            required
            type='text'
            className='mb-3'
            maxLength={32}
            hidden={editImage.edit !== image.idImage}
            onChange={(e) => updateForm({name : e.target.value})}
            value={editImage.name}
          />
          <Form.Label hidden={editImage.edit !== image.idImage}><b>Nombre científico (opcional)</b></Form.Label>
          <Form.Control 
            type='text'
            className='mb-3'
            maxLength={32}
            hidden={editImage.edit !== image.idImage}
            value={editImage.scientificName}
            onChange={(e) => updateForm({scientificName : e.target.value})}
          />
        </Card.Body>
      </Card>
    </Col>
  ));

  return (
    <Container>
      <AdminBar></AdminBar>
      <Container className='Layout'>
        <Container className='mb-5'>
          <h3 className='mb-5'><b><span className='green'>Galería - {folder.name}</span></b></h3>
          <Row>
            <Col>
              <Button onClick={(e) => setModalShowNew(!modalShowNew)} className='PrimaryBtn'>Subir imagen o video <ion-icon name="cloud-upload"/></Button>
            </Col>
            <Col className='text-end'>
              <Button onClick={(e) => setModalShowDelete(!modalShowDelete)} variant="danger">
                Eliminar carpeta <ion-icon class='icon' name='trash-sharp'/>
              </Button>
            </Col>
          </Row>
          <p className='mt-4 green'>Borra las imágenes de los productos o categorías para poder eliminarlas de la galería.</p>
          <div hidden={activeChecks === 0} className='text-end'>
            <Button onClick={e => {DeleteImages(e)}} variant='danger'>
              <ion-icon name="trash-outline"/>
            </Button>
          </div>
        </Container>
        <Container>
          <Row md={3} lg={3} xl={3} className="mt-3 g-4">
            {imageRows}
          </Row>
        </Container>
        <Row className='mt-5'>
          <Pagination className='justify-content-center'>{items}</Pagination>
        </Row>
      </Container>
    <NewImageModal
    show={modalShowNew}
    onHide={() => setModalShowNew(false)}
    title='Subir imagen / Video'
    idFolder={idFolder}
    />
    <DeleteFolderModal
    show={modalShowDelete}
    onHide={() => setModalShowDelete(false)}
    name={folder.name}
    idFolder={folder.idFolder}
    />
    </Container>
  );
}

export default AdminFolderPictures;