import React, { useState } from 'react';
import './components.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Environment from '../views/Environment';

function DeleteUserModal(props) {
  const [error, setError] = useState('');

  async function DeleteUser(e){
    fetch(`${Environment()}/login/deleteAccount`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email:props.email}),
    })
    .catch(err => {
      console.error(err);
      setError("En este momento no se puede modificar tu información. Inténtalo más tarde.");
    });
}

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Eliminar cuenta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          ¿Estás seguro de que quieres eliminar la cuenta?
          Perderás toda tu información y no podrás volver a ingresar.
        </p>
        <p className='text-danger'>
          {props.email}
        </p>
        
        {error}
      </Modal.Body>
      <Modal.Footer>
        <Button className='CancelButton' onClick={props.onHide}>Cancelar</Button>
        <Button className='btn-danger' onClick={(e) => DeleteUser(e)}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteUserModal;