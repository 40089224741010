import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Environment from './../views/Environment';
import NewCategoryModal from './newCategoryModal';
import ModifyCategoryModal from './modifyCategoryModal';
import Spinner from 'react-bootstrap/esm/Spinner';

function CategoryTable(){

    const [categories, setCategories] = useState([]);
    const [categoriesSearch, setCategoriesSearch] = useState([]);
    const [modalShowNew, setModalShowNew] = useState(false);
    const [modalShowModify, setModalShowModify] = useState(false);
    const [modalShowImage, setModalShowImage] = useState(false);
    const [alert, setAlert] = React.useState(true);
    const [alertText, setAlertText] = React.useState('');
    const [selectedPicture, setSelectedPicture] = useState(0);
    const [categoryModify, setCategoryModify] = useState(null);
    const [spinner, setSpinner] = useState(false);

    const handleCloseImage = () => setModalShowImage(false);
    function handleShowImage(e){
        setModalShowImage(true);
        setSelectedPicture(e.currentTarget.id);
    }

    function handleShowModify(e){
        setCategoryModify(e.currentTarget.id);
        setModalShowModify(true);
    }

    function filterCategories(e){
        e.preventDefault();
        const category = e.currentTarget.value;
        if(!category){
            setCategoriesSearch('');
        }
        else{
            setCategoriesSearch(categories?.filter((row) => 
                                row?.name?.match(new RegExp(category, "i"))
            ));
        }
    }

    useEffect(() => {
        fetch(`${Environment()}/categories/getCategories`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(data => data.json())
        .then((data) => {
            setCategories(data);
            setSpinner(false);
        })
        .catch(err => {
            console.error(err);
            setSpinner(false);
        });
    }, [])

    const categoryRows = Array.from((categoriesSearch.length < 1) ? categories : categoriesSearch).map((category, idx) => (
        <tr key={category.idCategory}>
          <td>{category.name}</td>
          <td><Image id={`${category.idImage}${category.imgExtension}`} onClick={(e) => {handleShowImage(e)}} style={{ width: "calc(50px + 3vw)" }} src={`${Environment()}/image${category.idImage}${category.imgExtension}`}></Image></td>
          <td>{category.shortDescription}</td>
          <td><Button data-cy={category.name + ' modify'} id={category.idCategory} onClick={(e) => handleShowModify(e)} className='SecondaryBtn'><ion-icon name="pencil-sharp"></ion-icon></Button></td>
          <td><Button data-cy={category.name + ' delete'} id={category.idCategory} onClick={(e) => DeleteCategory(e)} variant="outline-danger"><ion-icon name="trash-outline"></ion-icon></Button></td>
        </tr>                     
    ));

    function DeleteCategory(e){
        const idCategory = e.currentTarget.id;
        fetch(`${Environment()}/products/getProductsOfCategory/${idCategory}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(data => data.json())
        .then((data) => {
            if(data.length){
                setAlert(false);
                setAlertText('Elimina los productos de esta categoría o asígnales una distinta para poder eliminarla.')
            }
            else{
                fetch(`${Environment()}/categories/deleteCategory`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({idCategory:idCategory})
                })
                .then(() => {
                    window.location.reload();
                })
                .catch(err => {
                    console.error(err);
                });
            }
        });
    }

    return(
        <Container>
            <Alert hidden={alert} variant='danger'>{alertText}</Alert>
            < div className='mt-1 text-center'>
                <Spinner
                data-cy="categories spinner"
                hidden={!spinner}
                as="span"
                className='justify-content-center'
                animation="border"
                variant='success'
                size="lg"
                role="status"
                />
            </div>
            <Table responsive>
                <thead>
                    <tr>
                    <td><Form.Control
                            placeholder="Buscar categoría"
                            className="me-4"
                            style={{width:'auto'}}
                            onChange={(e) => {filterCategories(e)}}
                            />
                        </td>
                        <td colSpan={10} className='text-end'><Button href='/gestionarimagenes' className='PrimaryBtn me-2'> <ion-icon className='Icon' slot="start" name="images-outline"/> Galería</Button><Button data-cy="Create category" onClick={() => setModalShowNew(true)} className='PrimaryBtn'><ion-icon className='Icon' slot="start" name="add-outline"></ion-icon> Crear categoría</Button></td>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Imagen</th>
                        <th>Descripción corta</th>
                        <th>Modificar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {categoryRows}
                </tbody>
            </Table>
            <NewCategoryModal
            show={modalShowNew}
            onHide={() => setModalShowNew(false)}
            title='Crear categoría'
            description=''
            />
            <ModifyCategoryModal
            show={modalShowModify}
            onHide={() => setModalShowModify(false)}
            title='Modificar categoría'
            description=''
            id={categoryModify}
            />
            <Modal className='d-flex justify-content-center' centered size="lg" show={modalShowImage} onHide={handleCloseImage}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                <Image 
                style={{ width: "calc(50px + 40vw)" }} 
                src={`${Environment()}/image${selectedPicture}`}>
                </Image>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default CategoryTable;