import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Autocomplete, MarkerF } from '@react-google-maps/api';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import './../components/components.css';
import './views.css'
import Environment from './Environment';
import NavbarUser from '../components/navbar';
import DeleteUserModal from '../components/deleteUserModal';
import Logout from '../components/logout';
import FooterUser from '../components/footerUser';
const libraries = ["places"];

function MyAccount() {
  const [form, setForm] = useState({});
  const [user, setUser] = useState({})
  const [disabledInpt, setDisabledInpt] = useState({
    firstName: true,
    lastName: true,
    email: true,
    password: true,
    street: true,
    externalNumber: true,
    internalNumber: true,
    neighborhood: true,
    postalCode: true,
    location: true,
    marker: false,
    phone: true,
  })
  const [credentials, setCredentials] = useState({})
  const [error, setError] = useState('');
  const [deleteUser, setDeleteUser] = useState({state: false});

  function emailValidation() {
    /* eslint-disable-next-line */
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!regex.test(user.email)){
      setError('El correo electrónico no es válido.');
      return false;
    }
    return true;
  }

  function phoneValidation() {
    const regex = /^\d{10}$/
    if(!regex.test(user.phone)){
      setError('El número telefónico no contiene 10 dígitos numéricos.');
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function isLoggedIn() {
        const response = await fetch(`${Environment()}/login/isLoggedIn`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        
        response.json()
        .then((data) => {
            if(data.isLoggedIn){
                setForm({loggedIn: data.isLoggedIn, role: data.roleID, name: data.user, email: data.email});
            }
        })
        .catch(err => {
            console.log(err);
        }); 
    }

    isLoggedIn();

  }, []);

  useEffect(() => {
    if(form.email){
      fetch(`${Environment()}/login/getUser/${form.email}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json',
          }
      })
      .then(data => data.json())
      .then((data) => {
        if(data.length > 0){
          setUser(data[0]);
          setCenter({lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude)});
          setPosition({lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude)});
          updateForm({phoneCode:String(data[0].phone).slice(0, -10)});
          updateForm({phone:String(data[0].phone).slice(-10)});
          setCredentials(
          {
            password1:data[0].password,
            password2:data[0].password
          });
        }
      })
      .catch(err => {
          console.log(err);
      }); 
    }

  }, [form.email]);

    /**
  * updateForm
  * @description updates data of a form
  * @param value: new values of the form
  * @returns an updated form
  */
     function updateForm(value) {
      return setUser((prev) => ({ ...prev, ...value }));
  }

    /**
  * updateDisabledForm
  * @description updates data of a form
  * @param value: new values of the form
  * @returns an updated form
  */
     function updateDisabledForm(value) {
      const saveBtnUp = document.getElementById('saveBtnUp');
      const cancelBtnUp = document.getElementById('cancelBtnUp');
      const saveBtnDown = document.getElementById('saveBtnDown');
      const cancelBtnDown = document.getElementById('cancelBtnDown');
      saveBtnUp.hidden = false;
      cancelBtnUp.hidden = false;
      saveBtnDown.hidden = false;
      cancelBtnDown.hidden = false;
      return setDisabledInpt((prev) => ({ ...prev, ...value }));
  }

  /**
   * onSubmit
   * @description Posts the auth data to the api and receives the status
   * @param e: Context
   */
   async function onSubmit(e) {
    e.preventDefault()
    const credentials = { ...user };

    if(emailValidation() && phoneValidation()){

      fetch(`${Environment()}/login/isUserRegistered`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: form.email})
      })
      .then((data) => {
        if(data.status === 201){
          setError("El correo electrónico ya está registrado.");
        }
        else{
          fetch(`${Environment()}/login/modify`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(credentials),
          })
          .then((response) => {
            if(response.status === 200){
              window.location.reload();
            }
          })
          .catch(err => {
            console.error(err);
            setError("En este momento no se puede modificar tu información. Inténtalo más tarde.");
          });
        }
      })
      .catch(err => {
        console.error(err);
        setError("En este momento no se puede modificar tu información. Inténtalo más tarde.");
      });
    }
  }

  /**
  * updateCredentials
  * @description updates data of a form
  * @param value: new values of the form
  * @returns an updated form
  */
   function updateCredentials(value) {
    return setCredentials((prev) => ({ ...prev, ...value }));
  }

  function deleteAccount(){
    setDeleteUser({email:form.email, state:true})
  }

  const [autocomplete, setAutocomplete] = useState(null);

  const containerStyle = {
    height: '50vh',
    width: '100%'

  };

  const [position, setPosition] = useState({
    lat: 0,
    lng: 0
  });

  const [center, setCenter] = useState({
    lat: 20.5931,
    lng: -100.392
  });

  function onLoad (e) {
    setAutocomplete(e);
  }

  function onPlaceChanged (e) {
    if (autocomplete !== null) {
      setCenter(autocomplete.getPlace().geometry.location)
      setPosition(autocomplete.getPlace().geometry.location)
      updateForm({latitude: autocomplete.getPlace().geometry.location.lat(), longitude: autocomplete.getPlace().geometry.location.lng() });
    }
  }

  function dragMarker(marker){
    const address = document.getElementById('location');
    address.value = marker.latLng.lat().toString() + ', ' + marker.latLng.lng().toString();
    updateForm({latitude: marker.latLng.lat(), longitude: marker.latLng.lng()});
  }
  
  return (
    <div>
      <NavbarUser active='/micuenta'></NavbarUser>
      <Container className='Layout mb-5'>
        {!form.loggedIn &&
          <div>
            <p><b>Inicia sesión para ver y modificar tu cuenta. </b></p>
            <Button href='/login' className='SecondaryBtn'>Iniciar sesión</Button>
        </div>
        }
        {form.loggedIn &&
        <Row className='d-flex justify-content-center'>
          <Col md={8}>
            <Form onSubmit={onSubmit} id="registerForm">
                <Row>
                  <Col xs={7}>
                    <h5><b>Bienvenid@ <span className='green'>{form.name}</span></b></h5>
                  </Col>
                  <Col>
                    <Row className='justify-content-end me-2'>
                      <Button size='sm' className='PrimaryBtn' onClick={() => setError(Logout())}>Cerrar sesión</Button>
                      <Row className='mt-2 justify-content-center'>
                        <Col>
                          <Button size='sm' id='cancelBtnUp' hidden className='CancelButton' href='/micuenta'>
                            <span className='d-none d-lg-block'>Cancelar</span>
                            <span className='d-block d-lg-none'><ion-icon name="arrow-back-outline"></ion-icon></span>
                          </Button>
                        </Col>
                        <Col>
                          <Button size='sm' id='saveBtnUp' hidden className='PrimaryBtn' type='submit'>
                            <span className='d-none d-lg-block'>Guardar</span>
                            <span className='d-block d-lg-none'><ion-icon name="save-outline"></ion-icon></span>
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </Row>
                <Row className='justify-content-end me-2'>
                </Row>
              <Card className='mt-3 shadow LoginCard'>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre</Form.Label>
                    <InputGroup>
                      <Form.Control 
                        disabled={disabledInpt.firstName}
                        required type="text" 
                        id="firstName" 
                        maxLength={32} 
                        placeholder="Alejandro" 
                        onChange={(e) => updateForm({ firstName: e.target.value })}
                        defaultValue={user.firstName}
                      />
                      <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({firstName:false})}>
                        <ion-icon name="pencil"></ion-icon>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Apellido</Form.Label>
                    <InputGroup>
                      <Form.Control 
                        disabled={disabledInpt.lastName}
                        required
                        type="text" 
                        id="lastName" 
                        maxLength={32} 
                        placeholder="Rodríguez" 
                        onChange={(e) => updateForm({ lastName: e.target.value })} 
                        defaultValue={user.lastName}
                      />
                      <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({lastName:false})}>
                        <ion-icon name="pencil"></ion-icon>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Correo electrónico</Form.Label>
                    <InputGroup>
                      <Form.Control 
                        disabled
                        required 
                        type="text" 
                        id="email" 
                        maxLength={320} 
                        placeholder="nombre@ejemplo.com" 
                        onChange={(e) => updateForm({ email: e.target.value })} 
                        defaultValue={user.email}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <p>Contraseña</p>
                      <Button 
                        className='PrimaryBtn' 
                        href='/cambiarContrasena'
                      >
                      Cambiar contraseña
                      </Button>
                  </Form.Group>
                  {!disabledInpt.password &&
                  <Form.Group className="mb-3">
                    <Form.Label>Repetir contraseña</Form.Label>
                    <Form.Control
                      disabled={disabledInpt.password} 
                      required 
                      type="password" 
                      id="password2" 
                      maxLength={32} 
                      onChange={(e) => updateCredentials({ password2: e.target.value })}
                      defaultValue={credentials.password2}
                    />
                  </Form.Group>
                  }
                  <h6><b>Dirección</b></h6>
                    <p className='text-muted'>Por ahora sólo tenemos envíos a la ciudad de Querétaro.</p>
                    <Form.Group className="mb-3">
                      <Form.Label>Calle</Form.Label>
                      <InputGroup>
                        <Form.Control 
                          disabled={disabledInpt.street} 
                          required
                          type="text"
                          placeholder="Saturno" 
                          maxLength={64} 
                          onChange={(e) => updateForm({ street: e.target.value })}
                          defaultValue={user.street}
                        />
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({street:false})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Label>Número exterior</Form.Label>
                    <Form.Group className="mb-3">
                      <InputGroup>
                        <Form.Control 
                          disabled={disabledInpt.externalNumber} 
                          required
                          type="text"
                          maxLength={32}
                          placeholder="36B"
                          onChange={(e) => updateForm({ externalNumber: e.target.value })}
                          defaultValue={user.externalNumber}
                        />
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({externalNumber:false})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Label>Número interior (opcional)</Form.Label>
                    <Form.Group className="mb-3">
                      <InputGroup>
                        <Form.Control
                          disabled={disabledInpt.internalNumber}  
                          type="text"  
                          maxLength={32}
                          onChange={(e) => updateForm({ internalNumber: e.target.value })}
                          onKeyUp={(e) => updateForm({ internalNumber: e.target.value })}
                          defaultValue={user.internalNumber}
                        />
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({internalNumber:false})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Label>Colonia o fraccionamiento</Form.Label>
                    <Form.Group className="mb-3">
                      <InputGroup>
                        <Form.Control 
                          disabled={disabledInpt.neighborhood}
                          required 
                          type="text"  
                          maxLength={64}
                          placeholder="Lomas de Juriquilla"
                          onChange={(e) => updateForm({ neighborhood: e.target.value })}
                          defaultValue={user.neighborhood}
                        />
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({neighborhood:false})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Label>Código postal</Form.Label>
                    <Form.Group className="mb-3">
                      <InputGroup>
                        <Form.Control
                          disabled={disabledInpt.postalCode} 
                          required 
                          type="text"  
                          maxLength={5}
                          minLength={5}
                          placeholder="76226"
                          onChange={(e) => updateForm({ postalCode: e.target.value })}
                          defaultValue={user.postalCode}
                        />
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({postalCode:false})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col>
                        <Form.Label>Ubicación exacta</Form.Label>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({location:false, marker:true})}>
                          <ion-icon name="pencil"></ion-icon>
                        </Button>
                      </Col>
                    </Row>
                    <Form.Text muted>
                      Escribe tu dirección en la barra de búsqueda, selecciona una opción y ajusta el marcador para mayor precisión
                    </Form.Text>
                  </Form.Group>
                    <LoadScript id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
                      <GoogleMap
                        options={{
                          mapTypeControl: false,
                        }}
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                      >
                        <Autocomplete
                          onLoad={(e) => {onLoad(e)}}
                          onPlaceChanged={(e) => {onPlaceChanged(e)}}
                        >
                          <Form.Group className="mb-3">
                            <Form.Control 
                              disabled={disabledInpt.location}
                              id='location'
                              type="text"
                              required
                              placeholder="Buscar dirección"
                              style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width:'80%',
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "5%",
                              }}
                              />
                          </Form.Group>
                        </Autocomplete>
                        <MarkerF
                          position={position}
                          draggable={disabledInpt.marker}
                          onDrag={(marker) => {dragMarker(marker)}}
                        />
                      </GoogleMap>
                    </LoadScript>
                  <Form.Group className="mb-3">
                    <Form.Label>Teléfono</Form.Label>
                    <InputGroup>
                      <Form.Select
                        disabled={disabledInpt.phone}
                        variant="outline-secondary"
                        key={user.phoneCode}
                        defaultValue={user.phoneCode}
                        id="input-group-dropdown-2"
                        align="end"
                        onChange={(e) => updateForm({ phoneCode: e.currentTarget.value })}
                      >
                        <option value={1}>+1</option>
                        <option value={52}>+52</option>
                      </Form.Select>
                      <Form.Control
                        disabled={disabledInpt.phone} 
                        required 
                        type="tel" 
                        id="phone" 
                        maxLength={10}
                        onChange={(e) => updateForm({ phone: e.target.value })}
                        defaultValue={user.phone}
                      />
                      <Button className='PrimaryBtn' onClick={(e) => updateDisabledForm({phone:false})}>
                        <ion-icon name="pencil"></ion-icon>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <p className="mt-5">Si eliminas tu cuenta no podrás recuperarla.</p>
                  <Form.Group className="mb-3">
                    <Button variant='danger' onClick={() => deleteAccount()}>
                        Eliminar cuenta
                    </Button>
                  </Form.Group>
                  <p className="text-danger">{error}</p>
                  <br id="endline" />
                </Card.Body>
                <Card.Footer className="d-flex justify-content-center">
                  <Row>
                    <Col>
                      <Button id='saveBtnDown' hidden className='CancelButton' href='/micuenta'>Cancelar</Button>
                    </Col>
                    <Col>
                      <Button id='cancelBtnDown' hidden className='PrimaryBtn' type='submit'>Guardar</Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
        }
        <DeleteUserModal
          show={deleteUser.state}
          onHide={() => setDeleteUser(false)}
          email={deleteUser.email}
        />
      </Container>
      <FooterUser active='/micuenta'></FooterUser>
    </div>
  );
}

export default MyAccount;